<template>
  <div class="ma-5">
    <!-- Filtros de zones y fecha -->
    <v-row>
      <v-col cols="12" sm="6">
        <v-autocomplete
          density="compact"
          outlined
          prepend-inner-icon="mdi-filter-menu-outline"
          v-model="filter.zones"
          :items="zones"
          label="Filtrar por Desarrollos"
          multiple
          chips
          deletable-chips
          persistent-hint
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6">
        <dateRange
          v-model="filter.date"
          default="Últimos 30 días"
          ref="dateRange"
        ></dateRange>
      </v-col>
    </v-row>
    <!-- Filtros de descartado, posibilidad e inmediatez -->
    <v-row>
      <div>
        <v-btn
          class="ma-10"
          color="grey lighten-2 grey--text text--darken-4"
          @click="cleanFilters()"
        >
          Limpiar Filtros
        </v-btn>
        <v-btn color="primary ma-5 white--text" @click="fetchDataByReport()">
          Filtrar
        </v-btn>
      </div>
      <v-col class="mx-2" cols="4" align="center">
        <v-row>
          <v-switch
            v-model="showDiscarded"
            color="primary"
            flat
            label="Mostrar descartados por fase"
          >
          </v-switch>
          <v-tooltip max-width="300px" color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mx-1 my-3" icon v-bind="attrs" v-on="on">
                <v-icon color="primary"> mdi-information </v-icon>
              </v-btn>
            </template>

            <v-row class="pa-4">
              <v-col cols="12" class="ma-0 pa-0">
                Funcionalidad en vigor a partir de 2022.
              </v-col>
            </v-row>
          </v-tooltip>
        </v-row>
        <v-row>
          <v-switch
            v-model="showBudgetPhase"
            class="my-1"
            color="primary"
            flat
            label="Mostrar posibilidad por fase"
          ></v-switch>
        </v-row>
      </v-col>
    </v-row>
    <!-- Tabla -->
    <div v-if="loading">
      <v-card width="100vw" height="90vh" elevation="0">
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" justify="center" align="center">
              <v-progress-circular
                :width="5"
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="12" justify="center" align="center">
              <div>Cargando datos...</div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div v-else>
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        class="elevation-1"
      >
        <!-- groupHeader -->
        <template v-slot:[`header`]>
          <thead depressed outlined style="border: thin solid #E8E4E4;">
            <tr>
              <th colspan="4" class="text-center"></th>
              <th
                depressed
                outlined
                :colspan="totalColspan"
                :class="[
                  'text-center',
                  !showDiscarded ? 'white--text' : 'black--text'
                ]"
                :style="{
                  'background-color': !showDiscarded ? '#0091EA' : '#E0E0E0',
                  color: !showDiscarded ? '#0091EA' : '#E0E0E0'
                }"
              >
                {{ !showDiscarded ? "Activos" : "Descartados por fase" }}
                <v-chip
                  :color="!showDiscarded ? 'white' : 'black'"
                  class="v-chipActive"
                  :style="{
                    color: !showDiscarded ? '#0091EA' : '#E0E0E0'
                  }"
                >
                  {{ getValueAllLeads(type) }}
                </v-chip>
                <span class="percentStyle">
                  ({{
                    getPercent(
                      getValueAllLeads(type),
                      getValueAllLeads("total")
                    )
                  }})%</span
                >
              </th>
            </tr>
            <tr>
              <th colspan="4"></th>
              <!-- for by array with values tof, mof, bof -->
              <th
                v-for="header in subheading"
                :key="header.key"
                :colspan="header.colspan"
                :class="[
                  'text-center',
                  !showDiscarded ? 'blue--text text--accent-4' : 'black--text'
                ]"
                :style="{
                  border: !showDiscarded
                    ? ' thin solid #0091EA'
                    : ' thin solid #E0E0E0'
                }"
              >
                {{ header.name }}
                <v-chip
                  class="v-chipActive text-white"
                  :style="{
                    'background-color': !showDiscarded ? '#0091EA' : 'black',
                    color: 'white'
                  }"
                >
                  {{ getValueAllLeadsByTOF(header.key, type) }}
                </v-chip>
                <span class="percentStyle">
                  ({{
                    getPercent(
                      getValueAllLeadsByTOF(header.key, type),
                      getValueAllLeads(type)
                    )
                  }})%</span
                >
              </th>
            </tr>
          </thead>
        </template>
        <!-- Headers -->
        <template v-slot:[`header.totalByContact`]="{ header }">
          <span> Leads </span>
          <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="white"
                style="cursor: pointer;"
              >
                {{ getValueAllLeads("total") }}
              </v-chip>
            </template>
            <span>Activos: {{ getValueAllLeads("total") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.inmediatly`]>
          <div class="primary rounded-circle d-inline-block">
            <v-tooltip color="black" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  style="cursor: pointer;"
                  v-bind="attrs"
                  v-on="on"
                  class="pa-1"
                  size="22"
                  color="amber"
                >
                  mdi-lightning-bolt
                </v-icon>
              </template>
              <span> Lead contactado en menos de 5 minutos.</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:[`header.probability`]>
          <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <img
                style="cursor: pointer;"
                v-bind="attrs"
                v-on="on"
                class="logoThermometer"
                :src="thermometer"
              />
            </template>
            <span>Probables</span>
          </v-tooltip>
        </template>
        <template v-slot:[`header.finished`]>
          <div>
            Venta
            <v-chip class="v-chipActive" color="white">
              {{ getValueAllLeadsByPhase("finished", "active") }}
            </v-chip>
            <span class="percentStyle">
              {{
                getPercent(
                  getValueAllLeadsByPhase("finished", "active"),
                  getValueAllLeads("total")
                )
              }}%
            </span>
          </div>
        </template>
        <template v-slot:[`header.discarded`]>
          <div>
            Descartado
            <v-chip class="v-chipActive" color="white">
              {{ getValueAllLeads("discarded") }}
            </v-chip>
            <span class="percentStyle">
              {{
                getPercent(
                  getValueAllLeads("discarded"),
                  getValueAllLeads("total")
                )
              }}%
            </span>
          </div>
        </template>
        <!-- use for by headers -->
        <template
          v-for="header in customHeaders"
          v-slot:[`header.${header.value}`]
        >
          <div v-bind:key="header.value">
            <v-col class="pa-2" cols="12" sm="12">
              {{ header.text }}
              <v-chip class="v-chipActive" color="white">
                {{ getValueAllLeadsByPhase(header.value, type) }}
              </v-chip>
              <span class="percentStyle">
                {{
                  getPercent(
                    getValueAllLeadsByPhase(header.value, type),
                    getValueAllLeads("total")
                  )
                }}%
              </span>
            </v-col>
            <span
              v-if="showBudgetPhase"
              class="mx-auto budget-phase exchange"
              >{{ calculateAllBudgetByPhase(header.value) }}</span
            >
          </div>
        </template>
        <!-- Items -->
        <template v-slot:[`item.totalByContact`]="{ item }">
          <strong>
            {{ item.totalByContact }}
          </strong>
          <v-tooltip color="black" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span
                style="cursor: pointer;"
                v-bind="attrs"
                v-on="on"
                class="blue--text text--accent-4 percentStyle"
              >
                {{ countAllTypeByContac(item, "active") }}
              </span>
            </template>
            <span>Activos</span>
          </v-tooltip>
          <span class="grey--text text--darken-1 percentStyle">
            ({{
              getPercent(
                countAllTypeByContac(item, "active"),
                item.totalByContact
              )
            }}%)
          </span>
        </template>
        <template v-slot:[`item.inmediatly`]="{ item }">
          {{
            getPercent(
              countAllTypeByContac(item, "inmediatez"),
              item.totalByContact,
              0
            )
          }}%
        </template>
        <template v-slot:[`item.probability`]="{ item }">
          {{ countAllTypeByContac(item, "probability") }}
        </template>
        <template v-slot:[`item.finished`]="{ item }">
          <ItemDataReport
            :item="item"
            phase="finished"
            :total="getValuePhasByContact(item, 'finished', 'active')"
            :func="detecPhase"
          ></ItemDataReport>
        </template>
        <template v-slot:[`item.discarded`]="{ item }">
          <ItemDataReport
            :item="item"
            phase="discarded"
            :total="countAllTypeByContac(item, 'discarded')"
            :func="detecPhase"
          ></ItemDataReport>
        </template>
        <!-- for by customHeadears in items -->
        <template
          v-for="header in customHeaders"
          v-slot:[`item.${header.value}`]="{ item }"
        >
          <ItemDataReport
            v-bind:key="header.value"
            :item="item"
            :phase="header.value"
            :total="getValuePhasByContact(item, header.value, type)"
            :showBudgetPhase="showBudgetPhase"
            :budget="calculatePhaseBudgetByContact(item, header.value)"
            :func="detecPhase"
          ></ItemDataReport>
        </template>
      </v-data-table>
    </div>
    <ListLeadsByReportDetail
      :leads="detail.leads"
      :dialog="detail.dialog"
      :loading="detail.loading"
      @setDialog="value => (detail.dialog = value)"
    >
    </ListLeadsByReportDetail>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import dateRange from "@/components/reports-kpis/component/dateRange.component.vue";
import ItemDataReport from "./component/itemDataTableReport.vue";
import ListLeadsByReportDetail from "./component/ListLeadsByReportDetail";
export default {
  name: "ComertialReportV2",
  components: {
    dateRange,
    ItemDataReport,
    ListLeadsByReportDetail
  },
  data() {
    return {
      loading: true,
      showBudgetPhase: false,
      showDiscarded: false,
      type: "active", // active, discarded
      thermometer: `${process.env.BASE_URL}50rojo.png`,
      detail: {
        leads: [],
        dialog: false,
        loading: false
      },
      zones: [],
      filter: {
        zones: [],
        date: {
          start: "",
          end: ""
        }
      },
      monedaFormatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
      }),
      customHeaders: [],
      headers: [],
      phaseClass: {
        assigned: "purple lighten-5 deep-purple--text text--darken-4",
        "to-contact": "purple lighten-5 deep-purple--text text--darken-4",
        searching: "purple lighten-5 deep-purple--text text--darken-4",
        tracking: "purple lighten-5 deep-purple--text text--darken-4",
        "scheduled-tour": "purple lighten-5 deep-purple--text text--darken-4",
        "finished-tour": "purple lighten-5 deep-purple--text text--darken-4",
        offer: "purple lighten-5 deep-purple--text text--darken-4",
        downpayment: "blue lighten-5 blue--text text--darken-4",
        contract: "blue lighten-5 blue--text text--darken-4",
        "closing-trade": "blue lighten-5 blue--text text--darken-4",
        finished: "yellow lighten-5 brown--text text--darken-4",
        discarded: "grey lighten-2 grey--text text--darken-4"
      },
      subheading: [
        { key: "tof", name: "TOF", colspan: 0 },
        { key: "mof", name: "MOF", colspan: 0 },
        { key: "bof", name: "BOF", colspan: 0 }
      ],
      resume: {},
      items: []
    };
  },
  async mounted() {
    this.loading = true;
    await this.fetchfilters();
    this.handlerPhases();
    await this.fetchDataByReport();
    this.zones = this.filters.zones;
  },
  methods: {
    ...mapActions({
      getReport: "reports/fetchDataByComertialReport",
      getListLeads: "reports/fetchListLeadsByDetailReport",
      fetchfilters: "leads/fetchGetLeadsFilters"
    }),
    async detecPhase(contactId, phase) {
      this.detail.loading = true;
      this.detail.dialog = true;
      await this.getListLeads({
        contactId,
        phase,
        status: this.type,
        ...this.handlerFilters(),
        typeReport: "comertial"
      });
      this.detail.leads = this.leadsListByContact;
      this.detail.loading = false;
    },
    async fetchDataByReport() {
      this.loading = true;
      await this.getReport(this.handlerFilters());
      this.resume = this.comertialReport.resume;
      this.items = this.comertialReport.result;
      this.loading = false;
    },
    // handler filters, valid zones
    handlerFilters() {
      const newFilter = {};
      if (this.filter.zones.length > 0) {
        newFilter.zones = this.filter.zones;
      }
      if (this.filter.date.start && this.filter.date.end) {
        newFilter.date = this.filter.date;
      }
      return newFilter;
    },
    getPercent(count, total, decimals = 2) {
      if (total === 0) {
        return 0;
      }
      return ((count / total) * 100).toFixed(decimals);
    },
    // suma algun criterio de todos los leads de un contacto
    countAllTypeByContac(contact, type) {
      const keys = {
        total: "totalByPhase",
        active: "activeInPhase",
        discarded: "discardedInPhase",
        probability: "probability",
        inmediatez: "inmediatez"
      };
      let total = 0;
      contact.data.forEach(phase => {
        total += phase[keys[type]];
      });
      return total;
    },
    // devuelve el valor todos los leads por tipo(todos de la inmo)
    getValueAllLeads(type) {
      const keys = {
        total: "allLeads",
        active: "allActive",
        discarded: "allDiscarded"
      };
      const key = keys[type];
      if (!key) {
        return 0;
      }
      return this.resume[key];
    },
    // function by get value in resume by tof.allActive
    getValueAllLeadsByTOF(key, type) {
      const keys = {
        tof: "tof",
        mof: "mof",
        bof: "bof"
      };
      const types = {
        total: "allLeads",
        active: "allActive",
        discarded: "allDiscarded"
      };
      const select = keys[key];
      const discri = types[type];
      if (!key) {
        return 0;
      }
      return this.resume[select][discri];
    },
    // devuelve el valor de todos los leads por fase y tipo (headers)
    getValueAllLeadsByPhase(phase, type) {
      const keys = {
        active: "allActive",
        discarded: "allDiscarded"
      };
      const key = keys[type];
      if (!key) {
        return 0;
      }
      // valid if phase exist
      if (!this.resume.allByPhases[phase]) {
        return 0;
      }
      return this.resume.allByPhases[phase][key];
    },
    // devuelve el valor de todos los leads de un contacto por fase y tipo (items)
    getValuePhasByContact(contact, phase, key) {
      const keys = {
        total: "totalByPhase",
        active: "activeInPhase",
        discarded: "discardedInPhase",
        budgetMXN: "budgetMXN",
        budgetUSD: "budgetUSD",
        probability: "probability",
        inmediatez: "inmediatez"
      };
      const type = keys[key];
      if (!type) {
        return 0;
      }
      const data = contact.data.find(item => item.tracking_phase === phase);
      if (!data) {
        return 0;
      }
      return data[type];
    },
    calculatePhaseBudgetByContact(contact, phase) {
      const data = contact.data.find(item => item.tracking_phase === phase);
      if (!data) {
        return "-";
      }
      const mount = data.budgetMXN + data.budgetUSD * 20;
      if (mount === 0) {
        return "-";
      }
      return this.monedaFormatter.format(mount) + " MXN";
    },
    calculateAllBudgetByPhase(currentPhase) {
      const result = this.items.reduce((acc, cur) => {
        cur.data.forEach(phase => {
          if (phase.tracking_phase === currentPhase)
            acc[phase.tracking_phase] = {
              budgetMXN: acc[phase.tracking_phase]
                ? acc[phase.tracking_phase].budgetMXN + phase.budgetMXN
                : phase.budgetMXN,
              budgetUSD: acc[phase.tracking_phase]
                ? acc[phase.tracking_phase].budgetUSD + phase.budgetUSD
                : phase.budgetUSD
            };
        });
        return acc;
      }, {});
      if (!result[currentPhase]) {
        return "-";
      }
      return this.monedaFormatter.format(
        result[currentPhase]?.budgetMXN + result[currentPhase]?.budgetUSD * 20
      );
    },
    // calcula los headers de acuerdo a las phases activas
    handlerPhases() {
      let phases = this.phases.filter(phase => {
        return !["finished", "discarded", "unassigned"].includes(phase.value);
      });
      this.customHeaders = phases.map(phase => {
        return {
          text: phase.text,
          value: phase.value,
          class: this.phaseClass[phase.value],
          icon: "",
          color: "",
          txtcolor: "",
          justify: "center",
          align: "center",
          sortable: false
        };
      });
      this.headers = [
        {
          text: "Broker",
          value: "name",
          class: "primary white--text",
          icon: "",
          color: "",
          txtcolor: "",
          justify: "center",
          align: "start",
          sortable: true
        },
        {
          text: "Leads",
          value: "totalByContact",
          class: "primary white--text",
          icon: "",
          color: "",
          txtcolor: "",
          justify: "center",
          align: "center",
          sortable: true
        },
        {
          text: "",
          value: "inmediatly",
          width: "50px",
          class: "primary  white--text",
          icon: "",
          color: "",
          txtcolor: "",
          justify: "center",
          align: "center",
          sortable: false
        },
        {
          text: "",
          value: "probability",
          width: "50px",
          class: " white--text",
          icon: "",
          color: "",
          txtcolor: "",
          justify: "center",
          align: "center",
          sortable: false
        },
        ...this.customHeaders,
        {
          text: "Venta",
          value: "finished",
          class: "yellow lighten-5 brown--text text--darken-4",
          icon: "",
          color: "",
          txtcolor: "",
          justify: "center",
          align: "center",
          sortable: false
        },
        {
          text: "Descartado",
          value: "discarded",
          class: "grey lighten-2 grey--text text--darken-4",
          icon: "",
          color: "",
          txtcolor: "",
          justify: "center",
          align: "center",
          sortable: false
        }
      ];
      this.calculateColspanBySubheading();
    },
    // calcula el colspan de los subtitulos de acuerdo a las phases activas
    calculateColspanBySubheading() {
      const phaseMap = {
        assigned: "tof",
        "to-contact": "tof",
        searching: "mof",
        tracking: "mof",
        "scheduled-tour": "mof",
        "finished-tour": "mof",
        offer: "bof",
        downpayment: "bof",
        contract: "bof",
        "closing-trade": "bof"
      };
      this.customHeaders.forEach(header => {
        const funel = phaseMap[header.value];
        if (funel) {
          this.subheading.find(item => item.key === funel).colspan++;
        }
      });
    },
    cleanFilters() {
      this.filter.zones = [];
      this.$refs.dateRange.resetDateRange();
      this.fetchDataByReport();
    }
  },
  computed: {
    ...mapState({
      comertialReport: state => state.reports.comertialReport,
      leadsListByContact: state => state.reports.leadListByComertialReport,
      filters: state => state.leads.filters
    }),
    ...mapGetters("leads", {
      phases: "getPhases"
    }),
    totalColspan() {
      return this.subheading.reduce((acc, cur) => acc + cur.colspan, 0);
    }
  },
  watch: {
    showDiscarded() {
      // si se activa el filtro de descartados, se desactiva el de posibilidad
      this.type = this.showDiscarded ? "discarded" : "active";
      if (this.showDiscarded) this.showBudgetPhase = false;
    },
    showBudgetPhase() {
      // si se activa el filtro de posibilidad, se desactiva el de descartados
      if (this.showBudgetPhase) this.showDiscarded = false;
    }
  }
};
</script>

<style scoped>
.percentStyle {
  font-style: italic;
  font-size: 13px;
  font-size: smaller;
}
.v-chipActive {
  border-radius: 16px;
  font-size: 13px;
  height: 20px;
}
.logoThermometer {
  margin-top: 3px;
  width: auto;
  height: 40px;
}
.hover_tr:hover {
  background-color: #cb3838;
}
</style>
